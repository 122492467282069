import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '@components/layout';
import SEO from '@components/seo';
import Banner from '@components/banner';

const IndexPage = data => (
  <Layout>
    <SEO title="Shop" keywords={[`gatsby`, `application`, `react`]} />
    <Banner
      BannerData={data.data.allContentfulPageBanner.edges}
      centered={true}
    >
      <div className="bannerButtons">
        <Link className="primButton" to="/">
          Take me home
        </Link>
        <div className="or">or</div>
        <Link className="secButton" to="/contact">
          Contact us about the problem
        </Link>
      </div>
    </Banner>
    <div className="contactus">
      <h2>Don't be a stranger!</h2>
      <div className="mailto-button">
        <Link className="primButton blog" to={'/contact'}>
          Get in touch!
        </Link>
      </div>
    </div>
  </Layout>
);

export default IndexPage;

export const query = graphql`
  query fourOFourQuery {
    site {
      siteMetadata {
        url
      }
    }
    allContentfulPageBanner(filter: { page: { eq: "404" } }) {
      edges {
        node {
          title
          subHeading
          page
          image {
            fluid(maxWidth: 1800) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
    }
  }
`;
